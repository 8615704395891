import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { getPDFUrl } from '../lib/helpers'

import Layout from '../containers/layout'
import {Link} from 'gatsby'

export const query = graphql`
    query pdfQuery {
        allSanityPdfPage {
            edges {
                node {
                    _id
                    title
                    document {
                        asset {
                            url
                        }
                    }
                    slug {
                        current
                    }
                }
            }
        }
    }

`

const PdfDashboard = ({ data }) => { 
    return (
        <Layout>
            <div className=' pt-40 pb-20 w-3/4 m-auto h-full'>
                
                <h1 className="text-3xl pb-20">PDF Dashboard</h1>
                <ul className="flex justify-space flex-wrap">
                    {data.allSanityPdfPage.edges.map(({ node: pdf }) => (
                        <li key={pdf._id} className='flex flex-col w-40 border rounded-md p-4 m-2 justify-between'>
                            <h2 className='pb-4'>{pdf.title}</h2>
                            <Link to={getPDFUrl(pdf.slug.current)}>View PDF</Link>
                        
                        </li>
                    ))}
                </ul> 
            </div>
        </Layout>
    )
}

export default PdfDashboard